<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="_quotesSort"
          class="elevation-1"
          item-key="id"
        >
          <template v-slot:[`item.aproved`]="{ item }">
            <v-icon color="success" v-if="item.aproved">mdi-check-all</v-icon>
            <span class="orange--text" v-else><strong>SENT</strong> </span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ paymentDate(item.createdAt) }}
          </template>
          <template v-slot:[`item.procedure`]="{ item }">
            {{ getProcedureName(item) }}
          </template>
          <template v-slot:[`item.quotedAmount`]="{ item }">
            {{ item.quotedAmount | currency }}
          </template>
          <template v-slot:[`item.discount`]="{ item }">
            {{ item.discount | currency }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <template v-if="!item.aproved">
                {{/* Resend Quote PDF */}}
                <resend-quote @sended="getPatientData" :quote="item" />
              </template>

              {{/*Create Procedure*/}}
              <template
                v-if="
                  item.aproved &&
                  (item.procedure == null || item.procedure == undefined)
                "
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="success"
                      v-on="on"
                      icon
                      @click="toCreateProc(item)"
                    >
                      <v-icon>mdi-file-plus</v-icon></v-btn
                    >
                  </template>
                  <span>Create Procedure</span>
                </v-tooltip>
              </template>

              {{/* View Quote PDF */}}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="getPdf(item.id)"
                  >
                    <v-icon>mdi-file-pdf</v-icon>
                  </v-btn>
                </template>
                <span>View Quote</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
        <v-dialog
          v-model="dialogcheck"
          persistent
          :overlay="false"
          max-width="300px"
          transition="dialog-transition"
        >
          <v-card>
            <v-card-title primary-title>Approve Quote </v-card-title>
            <v-card-text class="text-center"> Are you sure ? </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="closeCheck()"> Cancel </v-btn>
              <v-btn color="primary" @click="approve()"> Approve </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogCreateProc"
          persistent
          :overlay="false"
          max-width="300px"
          transition="dialog-transition"
        >
          <v-card>
            <v-card-title primary-title> Create Procedure </v-card-title>
            <v-card-text class="text-center"> Are you sure ? </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="cancelCreateProc()">
                Cancel
              </v-btn>
              <v-btn
                :disabled="loading"
                :loading="loading"
                color="primary"
                text
                @click="confirmCreate()"
              >
                Create
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <document-viewer
          v-if="showDocuments"
          v-model="showDocuments"
          :documents="quotepdf"
        />
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { apiBaseUrl } from "@/enviorment";
import Vue from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import DocumentViewer from "../PdfSign/DocumentViewer.vue";
import ResendQuote from "@/components/Quotes/ResendQuote.vue";
import moment from "moment";
import { notifyError, notifySuccess } from "@/components/Notification";
export default Vue.extend({
  components: { DocumentViewer, ResendQuote },
  name: "crm-quotes",
  data() {
    return {
      loading: false,
      itemAprove: null,
      dialogCreateProc: false,
      itemCreateProc: null,
      dialogcheck: false,
      showDocuments: false,
      quotepdf: [],
      headers: [
        { text: "Id", value: "id", sortable: false },
        { text: "Type", value: "quote_type", sortable: false },
        { text: "Procedure", value: "procedure", sortable: false, width: 200 },
        {
          text: "Amount",
          value: "quotedAmount",
          sortable: false,
          align: "end",
        },
        { text: "Discount", value: "discount", sortable: false, align: "end" },
        { text: "Status", value: "aproved", sortable: false, align: "center" },
        { text: "Create At", value: "createdAt", sortable: false },
        { text: "", value: "actions", sortable: false, align: "center" },
      ],
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", ["quotes", "patientDetail"]),
    _quotesSort() {
      if (this.quotes.length == 0) {
        return [];
      }
      const sort = this.quotes.sort((a, b) => a.id < b.id);
      return sort;
    },
  },
  methods: {
    ...mapActions("crmMedicFormModule", [
      "actAproveQuote",
      "actCreateprocedure",
    ]),
    ...mapMutations("crmMedicFormModule", [
      "mutPatientDetails",
      "mutPatientQuotes",
    ]),
    openCheck(item) {
      this.dialogcheck = true;
      this.itemAprove = item;
    },
    async approve() {
      await this.actAproveQuote(this.itemAprove.id);
      this.getPatientData();
      this.closeCheck();
    },
    closeCheck() {
      this.dialogcheck = false;
      this.itemAprove = null;
    },

    async getPatientData() {
      this.loading = true;

      const patient = (
        await getAPI(`/patient/getPatient/${this.$route.params.uuid}`)
      ).data;

      this.mutPatientDetails(patient);
      this.mutPatientQuotes(patient.quotes);
      this.loading = false;
    },

    toCreateProc(item) {
      this.dialogCreateProc = true;
      this.itemCreateProc = item;
    },
    cancelCreateProc(item) {
      this.dialogCreateProc = false;
      this.itemCreateProc = null;
      this.loading = false;
    },
    confirmCreate() {
      this.loading = true;
      this.actCreateprocedure({ quoteUuid: this.itemCreateProc.id })
        .then(() => {
          this.getPatientData();
          this.cancelCreateProc();
          notifySuccess("Procedure created successfully");
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    paymentDate(date) {
      return new Date(date).toISOString().slice(0, 10);
    },
    async getPdf(id) {
      const pdf = {
        title: "Quote PDF",
        url: `${apiBaseUrl}/sprocedures/getQuotePdf/${id}`,
      };

      this.quotepdf[0] = pdf;

      this.showDocuments = true;
    },
    getProcedureName(item) {
      if (item.surgeryType != null) {
        return item.surgeryType.name;
      }
      return item.treatmentType.name;
    },
  },
});
</script>
<style lang="scss" scope></style>
