var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm._quotesSort,"item-key":"id"},scopedSlots:_vm._u([{key:"item.aproved",fn:function(ref){
var item = ref.item;
return [(item.aproved)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-all")]):_c('span',{staticClass:"orange--text"},[_c('strong',[_vm._v("SENT")])])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.paymentDate(item.createdAt))+" ")]}},{key:"item.procedure",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getProcedureName(item))+" ")]}},{key:"item.quotedAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.quotedAmount))+" ")]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.discount))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(!item.aproved)?[_vm._v(" "+_vm._s(/* Resend Quote PDF */)+" "),_c('resend-quote',{attrs:{"quote":item},on:{"sended":_vm.getPatientData}})]:_vm._e(),_vm._v(" "+_vm._s(/*Create Procedure*/)+" "),(
                item.aproved &&
                (item.procedure == null || item.procedure == undefined)
              )?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"success","icon":""},on:{"click":function($event){return _vm.toCreateProc(item)}}},on),[_c('v-icon',[_vm._v("mdi-file-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Create Procedure")])])]:_vm._e(),_vm._v(" "+_vm._s(/* View Quote PDF */)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.getPdf(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-pdf")])],1)]}}],null,true)},[_c('span',[_vm._v("View Quote")])])],2)]}}],null,true)}),_c('v-dialog',{attrs:{"persistent":"","overlay":false,"max-width":"300px","transition":"dialog-transition"},model:{value:(_vm.dialogcheck),callback:function ($$v) {_vm.dialogcheck=$$v},expression:"dialogcheck"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v("Approve Quote ")]),_c('v-card-text',{staticClass:"text-center"},[_vm._v(" Are you sure ? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.closeCheck()}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.approve()}}},[_vm._v(" Approve ")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","overlay":false,"max-width":"300px","transition":"dialog-transition"},model:{value:(_vm.dialogCreateProc),callback:function ($$v) {_vm.dialogCreateProc=$$v},expression:"dialogCreateProc"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Create Procedure ")]),_c('v-card-text',{staticClass:"text-center"},[_vm._v(" Are you sure ? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.cancelCreateProc()}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"color":"primary","text":""},on:{"click":function($event){return _vm.confirmCreate()}}},[_vm._v(" Create ")])],1)],1)],1),(_vm.showDocuments)?_c('document-viewer',{attrs:{"documents":_vm.quotepdf},model:{value:(_vm.showDocuments),callback:function ($$v) {_vm.showDocuments=$$v},expression:"showDocuments"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }